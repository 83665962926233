<template>
  <v-container class="mt-5">
    <v-col cols="12" sm="6" md="4" class="mt-md-n5">
      <ValidationObserver ref="obs" v-slot="{ passes }">
        <div style="display: flex;gap: 10px">
          <v-responsive width="400px">
            <ValidationProvider
              name="Email"
              :rules="!email ? `required` : `email`"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Email"
                v-model="email"
                type="email"
                class="rounded-small w-100"
                color="black"
                dense
                filled
                rounded
                ref="email"
                placeholder="Ingresa el email"
                :error-messages="errors"
                style="width: 100%"
              ></v-text-field>
            </ValidationProvider>
          </v-responsive>
          <v-btn
            class="accent shadow-4 px-8 "
            large
            rounded
            :loading="loadingAddToBL"
            @click.stop="passes(HandlerAddEmailToBL)"
          >
            Añadir al blacklist
          </v-btn>
        </div>
      </ValidationObserver>
    </v-col>
    <v-col cols="12" md="12">
      <v-data-table
        :loading="loadingTable"
        :headers="headers"
        :items="blackList"
        hide-default-footer
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="per_page"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" @click="HandlerRemoveEmailFromBL(item.email)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loadingTable: false,
      loadingAddToBL: false,
      blackList: [],
      page: 1,
      per_page: 10,
      email: "",
      headers: [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
          class: "primary--text",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
          class: "primary--text",
        },
      ],
    };
  },

  created() {
    this.getBlackList();
  },
  methods: {
    async getBlackList() {
      try {
        this.loadingTable = true;
        const { data } = await this.$store.dispatch(
          "usersIndex/GET_BLACKLIST",
          {
            page: this.page,
            perPage: this.per_page,
          }
        );
        this.blackList = data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingTable = false;
      }
    },
    async HandlerRemoveEmailFromBL(email) {
      try {
        await this.$store.dispatch("usersIndex/REMOVE_EMAIL_FROM_BL", {
          email,
        });
        this.getBlackList();
      } catch (error) {
        console.log(error);
      }
    },
    async HandlerAddEmailToBL() {
      try {
        this.loadingAddToBL = true;
        await this.$store.dispatch("usersIndex/ADD_EMAIL_TO_BL", {
          email: this.email,
        });
        this.getBlackList();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAddToBL = false;
      }
    },
  },
};
</script>

<style></style>
